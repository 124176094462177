<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>企业管理</a-breadcrumb-item>
      <a-breadcrumb-item>ACE</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-body">
      <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">

        <!-- <a-tab-pane key="2">
          <span slot="tab">预算管理</span>
          <fiscal v-if="activeKey === '2'"/>
        </a-tab-pane> -->

        <a-tab-pane key="1">
          <span slot="tab">SHOOT</span>
          <shootGrid v-if="activeKey === '1'"/>
        </a-tab-pane>

        <a-tab-pane key="6">
          <span slot="tab">SHORT</span>
          <shortGrid v-if="activeKey === '6'"/>
        </a-tab-pane>

        <a-tab-pane key="4">
          <span slot="tab">KICK</span>
          <kickGrid v-if="activeKey === '4'"/>
        </a-tab-pane>

        <a-tab-pane key="3">
          <span slot="tab">ZORO</span>
          <zoroGrid v-if="activeKey === '3'"/>
        </a-tab-pane>

        <a-tab-pane key="688">
          <span slot="tab">RSI</span>
          <rsiGrid v-if="activeKey === '688'"/>
        </a-tab-pane>
        
        <a-tab-pane key="88">
          <span slot="tab">M5</span>
          <m5Grid v-if="activeKey === '88'"/>
        </a-tab-pane>

        <a-tab-pane key="10">
          <span slot="tab">His</span>
          <hisGrid v-if="activeKey === '10'"/>
        </a-tab-pane>

        <a-tab-pane key="12">
          <span slot="tab">Sort</span>
          <sortGrid v-if="activeKey === '12'"/>
        </a-tab-pane>

        <!-- <a-tab-pane key="5">
          <span slot="tab">BK</span>
          <bkGrid v-if="activeKey === '5'"/>
        </a-tab-pane> -->

        <a-tab-pane key="8">
          <span slot="tab">STOCK</span>
          <stockDetail v-if="activeKey === '8'"/>
        </a-tab-pane>

      </a-tabs>
    </div>
  </div>
</template>

<script>
import fiscal from './fiscal'
import stockGrid from './stockGrid'
import shootGrid from './shootGrid'
import openGrid from './openGrid'
import kickGrid from './kickGrid'
import zoroGrid from './zoroGrid'
import hisGrid from './hisGrid'
import m5Grid from './m5Grid'
import sortGrid from './sortGrid'
import bkGrid from './bkGrid'
import rsiGrid from './rsiGrid'
import shortGrid from './shortGrid'
import stockDetail from './stockDetail'

export default {
  name: 'index',
  components: {
    fiscal,
    hisGrid,
    rsiGrid,
    sortGrid,
    stockGrid,
    shootGrid,
    openGrid,
    zoroGrid,
    kickGrid,
    m5Grid,
    shortGrid,
    bkGrid,
    stockDetail
  },

  data() {
    return {
      activeKey: '688',
    }
  },
  methods: {
    changeTab(key) {
      this.activeKey = key
    }
  }
}
</script>
